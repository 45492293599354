const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  status: state => state.user.status,
  roles: state => state.user.roles,
  setting: state => state.user.setting,
  permission_routers: state => state.permission.routers,
  permission_addRoutes: state => state.permission.addRoutes,
  addRouters: state => state.permission.addRouters,
  ubtn: state => state.myData.ubtn,
  userDetail: state => state.user.userDetail,
  errorLogs: state => state.errorLog.logs,
  category: state => state.electronic.category,
  categoryIndex: state => state.electronic.categoryIndex,
  activeModel:state=>state.user.activeModel,
  screen:state=>state.app.screen,
  configInfo: state => state.myData.configInfo,
  workspace: state => state.myData.workspace,
  dialogStatus:state => state.app.dialogStatus,
}
export default getters
