import createPersistedState from "vuex-persistedstate"
const bom = {
  state: {
    bomForm: {},
    catch_components: [],
  },
  mutations: {
    SET_BOM_FORM: (state, dat) => {
      state.bomForm = dat
    },
    GET_CATCHE_COMPONENTS (state, data) {
      state.catch_components = data
    }
  },
  actions: {
    // 设置button
    setBomData({ commit }, data) {
      return new Promise((resolve) => {
        commit('SET_BOM_FORM', data)
        resolve()
      })
    },
  },
  plugins: [createPersistedState({
    storage: window.localStorage
  })]
}
export default bom
