import router, {
  errorRoute,
  asyncRoutes,
  addRouteByLogin
} from '@/router'
import {Message, MessageBox} from 'element-ui'
import store from '../store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {
  getToken, exitApp
} from '@/utils/auth' // getToken from cookie
NProgress.configure({
  showSpinner: false
}) // NProgress Configuration
const whiteList = ['/login', '/login/logHome', '/authredirect', '/register', '/register/regHome', '/register/newUser'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (!sessionStorage.getItem("loginCase")) {
    sessionStorage.setItem("loginCase", to.path);
  }
  if (to.meta && to.meta.login !== 'no') {
    // 如果是no 则不需要登录
    console.log(getToken())
    if (getToken()) {
      //获取token
      if (to.path.includes('/logHome') || to.path === '/login/logHome') {
        // 如果登录以后继续前往登录页面 , 则返回首页
        next({
          path: '/'
        })
        NProgress.done()
      } else {
        if (to.meta.local === "home") {
          // 去往不需要权限的页面 , 主要是去往首页的
          next();
        } else {
          // 获取菜单权限
          const hasRoles = store.getters.permission_addRoutes && store.getters.permission_addRoutes.length > 0
          if (hasRoles) {
            next()
          } else {
            try {
              asycRoute().then(res => {
                setTimeout(() => {
                  next({
                    ...to,
                    replace: true
                  })
                }, 1000)
              }).catch(err => {
                NProgress.done()
                Message.error(err.err)
                // setTimeout(() => {
                //   exitApp()
                // }, 3000)
              })
              // next({ ...to, replace: true })
            } catch (error) {
              Message.error(error || 'Has Error')
            }
          }
        }
      }
    } else {
      next('/login/logHome')
    }
  } else {
    if (to.path.includes('/logHome') || to.path === '/login/logHome') {
      if (getToken()) {
        next({
          path: '/'
        })
        NProgress.done();
        return;
      }
    }
    next();
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})

export function asycRoute() {
  return new Promise(async (resolve, reject) => {
    const roles = await store.dispatch('user/getUserRoles')
    if (roles && roles.length < 1) {
      reject("错误")
      return false;
    }
    // 获取数据库权限
    const accessRoutes = await store.dispatch('permission/generateRoutes', {
      roles,
      addRouteByLogin
    }).catch(err => {
      reject(err)
      console.error(err)
    })
    // 与前端对照后剩余的权限
    // dynamically add accessible routes
    if (accessRoutes) {

      let ar = router.addRoutes(accessRoutes.concat(errorRoute).concat(asyncRoutes));
      console.log(ar)
      // router.addRoutes(asyncRoutes);
      console.log("路由加载完毕")
      resolve(accessRoutes);
    } else {
      reject()
    }
  })
}
