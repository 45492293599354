import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const ELECTRONI = [

  {
    path: '/ELECTRONI',
    component: Layout,
    redirect: '/ELECTRONI/librariesManage/',
    name: '元器件Two',
    children: [
      {
        path: 'librariesManage',
        component: MainLayout,
        name: '库管理S',
        icon: 'zonghe',
        redirect: '/ELECTRONI/librariesManage/libraryDetail',
        children: [
          { path: 'libraryDetail', component: () => import('@/views/ElectronicComponents/electronics/librariesManage/libraryDetail'), name: '元器件详情', icon: 'zonghe' },
          { path: 'startFlowProcess', component: () => import('@/views/ElectronicComponents/electronics/librariesManage/startFlowProcess'), name: '元器件申请修改', icon: 'zonghe' },
          { path: 'partcolor', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/partcolor'), name: '元器件状态颜色设置', icon: 'zonghe' },
          { path: 'partstate', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/partstate'), name: '元器件状态优先级设置', icon: 'zonghe' },
          { path: 'partweihu', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/partweihu'), name: '元器件可维护项设置', icon: 'zonghe' },
          { path: 'partyouxuan', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/partyouxuan'), name: '元器件优选优先级设置', icon: 'zonghe' },
          { path: 'partsecurity', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/partSecurity'), name: '元器件安全等级设置', icon: 'zonghe' },
          { path: 'partsameshow', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/partsameshow'), name: '元器件同步显示设置', icon: 'zonghe' },
          { path: 'partimpoartshow', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/partimpoartshow'), name: '元器件导入页面显示', icon: 'zonghe' },
          { path: 'componentsLeadin', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/componentsLeadin'), name: '元器件导入', icon: 'zonghe' },
          { path: 'componentsUpdate', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/componentsUpdate'), name: '元器件更新', icon: 'zonghe' },
          { path: 'dataBookImport', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/dataBookImport'), name: '数据手册导入', icon: 'zonghe' },
          { path: 'dataBookimpoartshow', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/dataBookimpoartshow'), name: '数据手册导入页面显示', icon: 'zonghe' },
          { path: 'BOMchoosedate', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/BOMchoosedate'), name: 'BOM选择数据', icon: 'zonghe' },
          { path: 'BOMMatches', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/BOMMatches'), name: 'BOM匹配', icon: 'zonghe' },
          { path: 'BOMform', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/BOMform'), name: '生成表单', icon: 'zonghe' },
        ]
      },
    ]
  },
  { path: '/updateCompare', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/single/updateCompare'), name: '元器件更新对比预览', icon: 'zonghe' },
];
export const ELECTRONIC = [

  {
    path: '/ELECTRONIC',
    component: Layout,
    redirect: '/ELECTRONIC/componentsLib/',
    name: '器件资源库',
    menuName: '器件资源库',
    meta: {
      title: "器件资源库",
      icon: "fa fa-list",
    },
    children: [
      { path: 'componentsLib', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/componentsLib'), name: '元器件库', menuName: '元器件库', meta: { title: "元器件库" } },
      { path: 'recycleBin', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/recycleBin'), name: '回收站', menuName: '回收站', meta: { title: "回收站" } },
      { path: 'historyRecord', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/historyRecord'), name: '历史记录', menuName: '历史记录', meta: { title: "历史记录" } },
      { path: 'propertyMaintenance', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/propertyMaintenance'), name: '属性维护', menuName: '属性维护', meta: { title: "属性维护" } },
      { path: 'partsSetting', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/partsSetting'), name: '元器件管理设置', menuName: '元器件管理设置', meta: { title: "元器件管理设置" } },
      { path: 'statistical', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/statistical'), name: '统计', menuName: '统计', meta: { title: "统计" } },
      // { path: 'datasheet', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/datasheet'), name: '数据手册', menuName: '数据手册', meta: { title: "数据手册" } },
      { path: 'mycollection', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/mycollection'), name: '我的收藏', menuName: '我的收藏', meta: { title: "我的自选库" } },
      { path: 'usedMycollection', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/usedMycollection'), name: '常用库', menuName: '常用库', meta: { title: "常用库" } },
      // { path: 'ErrorFeedback', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/ErrorFeedback'), name: '错误反馈', menuName: '错误反馈', meta: { title: "错误反馈" } },
      { path: 'ErrorFeedback', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/evaluationFeedback'), name: '评价及反馈', menuName: '评价及反馈', meta: { title: "评价及反馈" } },
      // { path: 'evaluationFeedback', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/evaluationFeedback'), name: '评价及反馈', menuName: '评价及反馈', meta: { title: "评价及反馈" } },
      { path: 'drectoryManage', component: () => import('@/views/ElectronicComponents/electronic/librariesManage/DirectoryManagement'), name: '优选目录管理', menuName: '优选目录管理', meta: { title: "目录类型管理" } },
    ]
  },
];

export const DATASHEET = [
  {
    path: '/DATASHEET',
    component: Layout,
    redirect: '/DATASHEET/datasheet/',
    name: '元器件数据手册',
    menuName:'数据手册',
    meta: {
      title: "数据手册",
      icon: "el-icon-notebook-2",
    },
    children: [
      { path: 'datasheet', component:  () => import('@/views/ElectronicComponents/electronic/librariesManage/datasheet'), name: '数据手册',menuName:'数据手册',meta:{title:"数据手册"} },
    ]
  },
];
