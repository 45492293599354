<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h, context) {
    const { icon, title } = context.props
    const vnodes = []

    // if (icon) {
    //   if (icon.includes('el-icon')) {
    //     vnodes.push(<i class={[icon, 'sub-el-icon']} />)
    //   } else {
    //     vnodes.push(<svg-icon icon-class={icon}/>)
    //   }
    // }

    // if (title) {
    //   vnodes.push(<span slot='title'>{(title)}</span>)
    // }
    // return vnodes
    if (icon) {
      const elHtml  = h('i',{
        style:{
          marginRight:"10px",
          fontSize:"18px",
        },  
        attrs: {
          'class': icon,
          'aria-hidden':"true"
        }
      })
      vnodes.push(elHtml)
    }

    if (title) {
      vnodes.push(<span slot='title'>{(title)}</span>)
    }
    return vnodes
  }
}
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}
</style>
