import Layout from "@/views/layout/index";

export const QUALITYINFOMATION = [
    {
        path: '/QUALITY',
        component: Layout,
        redirect: '/QUALITY/info/',
        name: '质量信息管理',
        menuName: '质量信息管理',
        meta: {
            title: "质量信息管理",
            icon: "el-icon-files",
        },
        children: [
            {
                path: 'info',
                component: () => import('@/views/QualityInformationManagement/QualityInformation/qualityInformation.vue'),
                name: '质量信息库',
                menuName: '质量信息库',
                meta: {title: "质量信息库"}
            },
            {
                path: 'stat',
                component: () => import('@/views/QualityInformationManagement/StatisticalAnalysis/statisticalAnalysis.vue'),
                name: '统计分析',
                menuName: '统计分析',
                meta: {title: "统计分析"}
            },
        ]
    },
];
