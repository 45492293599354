import Layout from "@/views/layout/index";

export const SYSTEMMONITOR = [
    {
        path: '/systemMonitor',
        component: Layout,
        redirect: '/systemMonitor/operateLogs',
        name: '系统监控',
        menuName: '系统监控',
        meta: {
            title: "系统监控",
            icon: "fa fa-paste",

        },
        children: [
            { path: 'operateLogs', component: () => import('@/views/SystemMonitor/operateLogs/index.vue'), name: '操作日志', menuName: '操作日志', meta: { title: "操作日志" } },
            { path: 'loginLogs', component: () => import('@/views/SystemMonitor/loginLogs/index.vue'), name: '登录日志', menuName: '登录日志', meta: { title: "登录日志" } },
        ]
    },
];
