import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";

export const COM = [
    {
        path: '/COM',
        component: Layout,
        redirect: '/COM/modelLibrary/',
        name: '通用电路通用电路库',
        menuName: '通用电路库',
        meta: {
            title: "通用电路库",
            icon: "fa fa-list",
        },
        children: [
            {
                path: 'modelLibrary',
                component: () => import('@/views/ElectronicComponents/electronic/librariesManage/componentsLib'),
                name: '通用电路库',
                menuName: '通用电路库',
                meta: {title: "通用电路库"}
            },
            {
                path: 'recycleBin',
                component: () => import('@/views/ElectronicComponents/electronic/librariesManage/recycleBin'),
                name: '通用电路回收站',
                menuName: '回收站',
                meta: {title: "回收站"}
            },
            {
                path: 'statisticalStatus',
                component: () => import('@/views/ElectronicComponents/electronic/librariesManage/statistical'),
                name: '通用电路统计',
                menuName: '统计',
                meta: {title: "统计"}
            },
            {
                path: 'errorBack',
                component: () => import('@/views/ElectronicComponents/electronic/librariesManage/ErrorFeedback'),
                name: '通用电路错误反馈',
                menuName: '错误反馈',
                meta: {title: "错误反馈"}
            },
            /*{
                path: 'cbbSetting',
                component: () => import('@/views/ElectronicComponents/electronic/librariesManage/partsSetting'),
                name: '通用电路管理设置',
                menuName: '通用电路管理设置',
                meta: {title: "通用电路管理设置"}
            },*/
        ]
    },
    {
        path: '/COM_DATABOOK',
        component: Layout,
        redirect: '/COM_DATABOOK/databook/',
        name: '通用电路数据手册',
        menuName: '数据手册',
        meta: {
            title: "数据手册",
            icon: "el-icon-notebook-2",
        },
        children: [
            {
                path: 'databook',
                component: () => import('@/views/ElectronicComponents/electronic/librariesManage/datasheet'),
                name: '数据手册',
                hidden: true,
                menuName: '数据手册',
                meta: {title: "数据手册"}
            },
        ]
    },
    {
        path: '/COM_TYPE',
        component: Layout,
        redirect: '/COM_TYPE/attrTemplate/',
        name: '通用电路类目管理',
        menuName: '类目管理',
        meta: {
            title: "类目管理",
            icon: "fa fa-clone",
        },
        children: [
            {
                path: 'classTemplate',
                component: () => import('@/views/CategoryManagement/category/outlineManage/categoryManage'),
                name: '类通用电路目管理',
                menuName: '类目管理',
                meta: {title: "类目管理"}
            },
            {
                path: 'attrTemplate',
                component: () => import('@/views/CategoryManagement/category/outlineManage/attributeTemp'),
                name: '通用电路属性模板',
                menuName: '属性模板',
                meta: {title: "属性模板"}
            },
            {
                path: 'filedSet',
                component: () => import('@/views/CategoryManagement/category/outlineManage/fieldRechecking'),
                name: '通用电路字段配置',
                menuName: '字段配置',
                meta: {title: "字段配置"}
            },
            {
                path: 'categoryImg',
                component: () => import('@/views/CategoryManagement/category/outlineManage/categoryPicture'),
                name: '通用电路类目示意图',
                menuName: '类目示意图',
                meta: {title: "类目示意图"}
            },
            {
                path: 'flowTemplate',
                component: () => import('@/views/CategoryManagement/category/outlineManage/appriveTemplateManage'),
                name: '通用电路审批模板',
                menuName: '审批模板',
                meta: {title: "审批模板"}
            },
        ]
    },
    {
        path: '/COM_CODERULE',
        component: Layout,
        redirect: '/COM_CODERULE/ruleManagement/',
        name: '通用电路编码规则',
        menuName: '编码规则',
        meta: {
            title: "编码规则",
            icon: "fa fa-tasks",
        },
        children: [
            {
                path: 'ruleManagement',
                component: () => import('@/views/CodeRuleManagement/coderm/codeManage/codePage'),
                hidden: true,
                name: '通用电路编码规则管理',
                menuName: '编码规则管理',
                meta: {title: "编码规则管理", activeMenu: "/CODERULE"}
            },

        ]
    },
    /*{
        path: '/MESSAGES',
        component: Layout,
        redirect: '/MESSAGES/innerMessages/',
        name: '通用电路消息中心',
        menuName: '消息中心',
        meta: {
            title: "消息中心",
            icon: "fa fa-comments",

        },
        children: [
            {
                path: 'allMessages',
                component: () => import('@/views/MessageCenter/message/innerMessage/allMessage'),
                name: '通用电路全部消息',
                menuName: '全部消息',
                meta: {title: "全部消息"}
            },
            {
                path: 'unreadMessages',
                component: () => import('@/views/MessageCenter/message/innerMessage/unreadMessage'),
                name: '通用电路未读消息',
                menuName: '未读消息',
                meta: {title: "未读消息"}
            },
            {
                path: 'readMessages',
                component: () => import('@/views/MessageCenter/message/innerMessage/readMessage'),
                name: '通用电路已读消息',
                menuName: '已读消息',
                meta: {title: "已读消息"}
            },
        ]
    },*/
    {
        path: '/COM_BASEUSERS',
        component: Layout,
        redirect: '/COM_BASEUSERS/myAppConfigs/',
        name: '通用电路组织架构',
        menuName: '组织架构',
        meta: {
            title: "组织架构",
            icon: "fa fa-cogs",
        },
        children: [
            {
                path: 'employeesManages',
                component: () => import('@/views/organizationalStructure/baseuser/myConfiguration/employeesManage'),
                name: '通用电路员工管理',
                menuName: '员工管理',
                meta: {
                    title: "员工管理",
                },
            },
            {
                path: 'webRolePermisss',
                component: () => import('@/views/organizationalStructure/baseuser/webRole/webRolePermiss'),
                name: '通用电路角色管理',
                menuName: '角色管理',
                meta: {
                    title: "角色管理",
                },
                icon: 'zonghe'
            },
            /*{
                path: 'webUserRolePermisss',
                component: () => import('@/views/organizationalStructure/baseuser/webRole/webUserRolePermiss'),
                name: '通用电路人员角色',
                menuName: '人员角色',
                meta: {
                    title: "人员角色",
                },
                icon: 'zonghe'
            },*/

        ]
    },
    {
        path: '/COM_FLOWS',
        component: Layout,
        redirect: '/COM_FLOWS/waitDos/',
        name: '通用电路流程应用',
        menuName: '流程应用',
        meta: {
            title: "流程应用",
            icon: "fa fa-sitemap",
        },
        children: [
            {
                path: 'waitDos',
                component: () => import('@/views/ApprovalProcess/approval/waitDo'),
                name: '通用电路待办审批',
                menuName: '待办审批',
                meta: {title: "待办审批"}
            },
            {
                path: 'processDesigns',
                component: () => import('@/views/ApprovalProcess/approval/processDesign'),
                name: '通用电路流程设计',
                menuName: '流程设计',
                meta: {title: "流程设计"}
            },
            {
                path: 'runningProcesss',
                component: () => import('@/views/ApprovalProcess/approval/runningProcess'),
                name: '通用电路运行中流程',
                menuName: '运行中流程',
                meta: {title: "运行中流程"}
            },
            {
                path: 'yetDos',
                component: () => import('@/views/ApprovalProcess/approval/yetDo'),
                name: '通用电路办审批',
                menuName: '已办审批',
                meta: {title: "已办审批"}
            },
            {
                path: 'mySubmits',
                component: () => import('@/views/ApprovalProcess/approval/mySubmit'),
                name: '通用电路我提交的',
                menuName: '我提交的',
                meta: {title: "我提交的"}
            },
        ]
    },
];
