import Cookies from 'js-cookie'
import store from '@/store'
const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function exitApp() {
  store.dispatch('user/LogOut').then(() => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    Cookies.remove(TokenKey)
    window.location.href = location.protocol + "//" +location.host+"/#/login";
    window.location.reload();
    sessionStorage.setItem("loginCase","/");
  })
}
