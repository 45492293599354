import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const CATEGOR = [];
export const CATEGORY = [{
  path: '/CATEGORY',
  component: Layout,
  redirect: '/CATEGORY/categoryManage/',
  name: '类目',
  menuName: '类目管理',
  meta: {
    title: "类目管理",
    icon: "fa fa-clone",
  },
  children: [
        {
          path: 'categoryManage',
          component: () => import('@/views/CategoryManagement/category/outlineManage/categoryManage'),
          name: '类目管理',
          menuName: '类目管理',
          meta: {
            title: "类目管理",
          },
        },
        {
          path: 'attributeTemp',
          component: () => import('@/views/CategoryManagement/category/outlineManage/attributeTemp'),
          name: '属性模板管理',
          menuName: '属性模板管理',
          meta: {
            title: "属性模板管理",
          }
        },
        {
          path: 'fieldRechecking',
          component: () => import('@/views/CategoryManagement/category/outlineManage/fieldRechecking'),
          name: '字段配置',
          menuName: '字段配置',
          meta: {
            title: "字段配置",
          },
        },
        {
          path: 'categoryPicture',
          component: () => import('@/views/CategoryManagement/category/outlineManage/categoryPicture'),
          name: '类目示意图',
          menuName: '类目示意图',
          meta: {
            title: "类目示意图",
          },
        },
        {
          path: 'approveTemplate',
          component: () => import('@/views/CategoryManagement/category/outlineManage/appriveTemplateManage'),
          name: '审批模板',
          menuName: '审批模板',
          meta: {
            title: "审批模板",
          },
        }
    ]
}, 
];