import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const OPM = [
];
export const OPMA = [
  {
    path: '/OPMA',
    component: Layout,
    redirect: '/OPMA/webButtonList/',
    name: '基础运维',
    menuName:'基础运维',
    meta: {
      title: "基础运维",
      icon:"fa fa-ticket",
    },
    children: [
      { path: 'webButtonList', hidden:true, component:  () => import('@/views/operationMaintenance/opma/modelManage/webButtonList'), name: 'Web按钮',menuName:'Web按钮',meta:{title:"Web按钮",activeMenu:"/OPMA"}},
      // { path: 'bufferManage', component:  () => import('@/views/operationMaintenance/opma/logBuffer/bufferManage'), name: '缓存管理',menuName:'缓存管理',meta:{title:"缓存管理"}},
    ]
  },
];