<template>
  <!--  顶部-->
  <div class="snav">
    <div>
      <router-link to="/">
        <img :src="`/api/v1/qingqiu/staticResource/iconImg/logo.png`" style="height: 28px;">
      </router-link>
      <router-link to="/">
        <i class="el-icon-arrow-left"
           style="position: relative; top: 3px; color: #fff; font-weight: bold; font-size: 18px; padding-left: 20px;"></i>
      </router-link>
      <span style="position: relative;top: 3px;">
      <i @click="toggleSideBar" v-if="sidebar.opened" class="el-icon-more toggleSidebar" style=""></i>
      <i @click="toggleSideBar" v-else class="el-icon-more-outline toggleSidebar" style=""></i>
    </span>
      <!--    元器件/模块电路-->
      <div class="libcategory">
      <span v-for="(item,index) in modelList" :key="index"
            @click="selectLib(item)"
            :class="activeModel==item?'active':''"
      >
        {{ item }}
      </span>
      </div>
    </div>
    <div style="display: flex;justify-content: flex-start;align-items: center;">
      <div style="position: relative;font-size: 20px;color: #FFFFFF;margin-right: 10px;" @mouseenter="show=true" @mouseleave="show=false">
        <i class="el-icon-bell" style="width: 20px;cursor: pointer;"></i>
        <div class="message-num" v-if="unReaderNum > 0">{{unReaderNum}}</div>
        <transition name="el-zoom-in-top">
          <div v-show="show&&unReaderNum > 0" style="position: absolute;top: 40px;left: -100px;z-index: 10000;">
            <div class="message-list">
              <div class="item" v-for="(it,i) in msgList" :key="i" @click="lookDetail(it)">
                <div class="left"><i class="el-icon-bell" style="width: 20px"></i></div>
                <div class="right">
                  <div class="top">
                    <div class="t-left">{{ it.msgTittle }}</div>
                    <div class="t-right">[流程消息]</div>
                  </div>
                  <div class="time">{{ it.createTime }}</div>
                </div>
              </div>
              <div @click="showMore" style="width: 100%;padding-top:10px;height:30px;text-align: center;color: #333;font-size: 13px;cursor: pointer;">
                查看更多
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="hasClient">
        <a href="/api/v1/client/download" style="margin-left: 10px;" class="download-client-btn" target="_blank">下载客户端</a>
      </div>
      <div>
        <div class="pull-left">
          <img style="height:28px;margin-right: 5px;" src="/api/v1/qingqiu/staticResource/iconImg/user.png" alt=""
               srcset="">
        </div>
        <div class="pull-left" style="line-height: 28px;margin-right: 20px;color:#fff;">
          {{ userDetail.userName }}
        </div>
      </div>
      <div>
        <el-dropdown @command="scomand" :hide-on-click="false" style="display: table-cell;vertical-align:middle">
        <span class="el-dropdown-link">
          <i class="icon icon-align-justify" style="font-size:22px"></i>
        </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">修改密码</el-dropdown-item>
            <el-dropdown-item command="2">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <reset-pwd ref="resetPwdRef"></reset-pwd>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {exitApp} from "@/utils/auth";
import {
  getClientList,
} from "@/api/ele";
import {
  getMessageNum,
  postMessagePage,
} from "@/api/message";
import ResetPwd from "@/components/public/ResetPwd";

let ws = null;
let timer = null;

export default {
  components: {ResetPwd},
  data() {
    return {
      modelList: [],
      hasClient: false,
      unReaderNum:0,
      ws:{},
      show:false,
      msgList:[],
    };
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "activeModel", "permission_addRoutes", "device", "userDetail", "name"]),
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    scomand(a) {
      if (a == 2) {
        exitApp();
      } else if (a == 1) {
        this.$refs.resetPwdRef.init();
      }
    },
    getUserDetail() {
      this.$store.dispatch("user/GetUserInfo", {}).then(() => {
      });
    },
    //切换 cms/cbb，重新获取侧边栏
    selectLib(item) {
      this.modelActive = item;
      this.$store.dispatch("user/setActiveModel", item).then(() => {
        let ro = this.showRoutes();
        if (ro.length > 0) {
          this.$router.push(ro[0].redirect)
        }
      });
    },
    showRoutes() {
      return this.permission_addRoutes.filter(r => {
        return r.meta.menuFrom == this.activeModel
      })
    },
    initActiveModel() {
      let arr = [];
      this.permission_addRoutes.filter(r => {
        arr.push(r.meta.menuFrom);
      })
      let list = Array.from(new Set(arr));
      this.modelList = list;
      if (!this.activeModel) {
        const {meta} = this.$route
        this.$store.dispatch("user/setActiveModel", meta.menuFrom).then(() => {
        });
      }
    },
    getClientInfo() {
      getClientList()
          .then(list => {
            if (list.length > 0) {
              this.hasClient = true;
            }
          });
    },
    lookDetail(item, index) {
      this.$router.push({path: "/MESSAG/innerMessage/messageDetail", query: {id: item.id, status: item.status}});
    },
    showMore() {
      this.$router.push({path: "/MESSAGE/allMessage"});
    },
    getMessage() {
      getMessageNum().then(res => {
        this.unReaderNum = parseInt(res.data);
        let par = {
          num: 1,
          size: 5,
          msgType: '',
          status: '0',
        }
        postMessagePage(par).then(r => {
          this.msgList = r.data;
          if (ws) {
            ws.close();
            ws = null;
          }
          // 开启socket
          this.createSocket();
        })
      })
    },
    // start - - - - - - socket
    createSocket() {
      const userId = JSON.parse(localStorage.getItem('userInfo')).id;
      let w = location.protocol.indexOf("https") > -1 ? "wss" : "ws";
      let h = location.host;
      let socketUrl = `${w}://${h}/notification/${userId}`;
      ws = new WebSocket(socketUrl);
      ws.addEventListener("open",this.openHandle);
      ws.addEventListener("close",this.closeHandle);
      ws.addEventListener("message",this.messageHandle);
      ws.addEventListener("error",this.errorHandle);
    },
    openHandle() {
      console.log("socket连接成功")
    },
    closeHandle() {
      console.log("socket已关闭")
      // 断开重连
      this.restartWs();
    },
    messageHandle(e) {
      console.log("socket接收消息")
      console.log(e)
      this.unReaderNum = parseInt(e.data);
    },
    errorHandle() {
      console.log("socket错误!!!")
    },
    // 断开重连
    restartWs() {
      console.log("socket连接失败,准备重连...")
      timer = setInterval(() => {
        this.createSocket();
        if (ws && ws.readyState === 0) {
          clearInterval(timer);
          timer = null;
        }
      },2000)
    },
    // 手动关闭
    handleClose() {
      if (ws) {
        ws.close();
      }
    },
    // 手动发送方法
    handleSend() {
      if (ws) {
        ws.send("ping");
      }
    },
    // socket - - - - - - end
  },
  mounted() {
    this.getUserDetail();
    this.initActiveModel();
    this.getClientInfo();
    this.getMessage();
  },
};
</script>

<style lang='scss' scoped>
.message-num{
  position: absolute;
  top: 0;
  right: -5px;
  background: #d70505;
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 10px;
  text-align: center;
}
.message-list{
  width: 260px;
  background-color: #fff;
  box-shadow: 0 0 3px 3px #eee;
  .item{
    color: #0a80ff;
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #f5f6f7;
    cursor: pointer;
    &:hover{
      background-color: #f5f6f7;
    }
    .left{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ee6f2d;
      color: #FFFFFF;
      font-size: 20px;
      margin-right: 10px;
    }
    .right{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: #333;
      font-size: 13px;
      .top{
        display: flex;
        justify-content: center;
        align-items: center;
        .t-left{}
        .t-right{
          font-size: 12px;
          color: #999;
          margin-left: 30px;
        }
      }
      .time{
        font-size: 11px;
        margin-top: 5px;
      }
    }
  }
}
.snav {
  height: 44px;
  background: #293c55;
  line-height: 1.1;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .toggleSidebar {
    font-size: 20px;
    margin-left: 20px;
    cursor: pointer;
    color: #fff;
  }
}

.libcategory {
  color: rgb(202, 199, 199);
  display: inline-block;
  margin-left: 40px;

  span {
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }

  .active {
    color: #fff;
    font-size: 15px;
    border-bottom: 2px solid #fff;
    padding-bottom: 3px;
  }
}

.download-client-btn {
  color: rgb(202, 199, 199);
  margin: 0 15px;
  line-height: 28px;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
</style>
