import Layout from "@/views/layout/index";

export const TOOLS = [
    {
        path: '/TOOLS',
        component: Layout,
        redirect: '/TOOLS/bomCompare/',
        name: '工具',
        menuName: '工具',
        meta: {
            title: "工具",
            icon: "el-icon-document-copy",
        },
        children: [
            {
                path: 'bomCompare',
                component: () => import('@/views/Tools/BomComparison/index'),
                name: 'BOM比较',
                menuName: 'BOM比较',
                meta: {title: "BOM比较"}
            },
        ]
    },
];
