import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const DSNREVIE = [
  
 
];
export const DSNREVIEW = [

  {
    path: '/DSNREVIEW',
    component: Layout,
    redirect: '/DSNREVIEW/dataReview/',
    name: '设计审查',
    menuName:'设计审查',
    meta: {
      title: "设计审查",
      icon: "fa fa-retweet",
    },
    children: [
      { path: 'dataReview', component:  () => import('@/views/DSNREVIEW/captureReview/dataReview'), name: '设计内容审查',menuName:'设计审查',meta:{title:"设计审查"} },
      { path: 'reviewSet', component:  () => import('@/views/DSNREVIEW/captureReview/reviewSet'), name: '审查配置',menuName:'审查配置',meta:{title:"审查配置"}},
          
    ]
  },
];