<template>
  <div :class="classObj" class="app-wrapper" v-if="ubtn.setIO">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <navbar></navbar>
    <sidebar class="sidebar-container" />
    <div :class="{hasTagsView:needTagsView}" class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <tags-view v-if="needTagsView" />
      </div>
      <div :class="needTagsView?'scroll-tag-view':'scroll-all-view'">
        <app-main />
      </div>
    </div>
    <!-- <div :class="{hasTagsView:needTagsView}" class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar />
        <tags-view v-if="needTagsView" />
      </div>
      <app-main />
      <right-panel v-if="showSettings">
        <settings />
      </right-panel>
    </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import RightPanel from '@/components/RightPanel'
import ResizeMixin from './mixin/ResizeHandler'
// import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components'
import { AppMain , Sidebar ,Navbar , TagsView} from './components'
export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    // RightPanel,
    // Settings,
    Sidebar,
    TagsView
  },
  mixins: [ResizeMixin],
  data() {
    return {};
  },
  computed: {
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      // showSettings: state => state.settings.showSettings,
      ubtn:state=>state.myData.ubtn,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    initBtn(){
      if(!this.ubtn.setIO) {
        this.$store.dispatch("myData/getUserRoleButton", {}).then(res => {
          
        });
      }
    },
    initScreen(){
      let screenObj = {}; 
      screenObj.width = document.documentElement.clientWidth; //窗口宽度
      screenObj.height = document.documentElement.clientHeight; //窗口高度
      this.$store.dispatch("app/setScreen",screenObj);
      window.addEventListener("resize",()=>{
        screenObj.width = document.documentElement.clientWidth; //窗口宽度
        screenObj.height = document.documentElement.clientHeight; //窗口高度
        this.$store.dispatch("app/setScreen",screenObj);
      })
    }
  },
  mounted(){
    this.initScreen();
  },
  created(){
    this.initBtn()
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

</style>