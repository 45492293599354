const home = [
  {
    path:"/",
    name: "Home",
    meta:{
      login:"no"
    },
    redirect: '/partindex',
  },
  {
    path:"/devCompare",
    name: "devCompare",
    meta:{
      login:"yes"
    },
    component: () => import('@/views/ElectronicComponents/electronic/librariesManage/compontends/DeviceCompare'),
  },
  {
    path: '/partindex',
    name: 'partindex',
    meta:{
      login:"no"
    },
    component: () => import('@/views/homePage/PartView/partindex'),
  },
  {
    path: '/partlists',
    name: 'partlists',
    meta:{
      login:"no"
    },
    component: () => import('@/views/homePage/PartView/partlists')
  },
  {
    path: '/partdetail',
    name: 'partdetail',
    meta:{
      login:"no"
    },
    component: () => import('@/views/homePage/PartView/partdetail'),
  },
  {
    path: '/partclassify',
    name: 'partclassify',
    meta:{
      login:"no"
    },
    component: () => import('@/views/homePage/PartView/partclassify'),
  },
  {
    path: '/partpayment',
    name: 'partpayment',
    meta:{
      login:"no"
    },
    component: () => import('@/views/homePage/PartView/partpayment'),
  },
  {
    path: '/login',
    name: 'Login',
    meta:{
      login:"no"
    },
    component: () => import('@/views/homePage/login/index'),
    redirect: '/login/logHome',
    children: [
      {
        path: 'logHome',
        name: 'loginHome',
        meta:{login:"no"},
        component: () => import('@/views/homePage/login/logHome'),
      },
    ]
  },
  {
    path: '/partsloader',
    name: 'partsloader',
    meta:{
      login:"no"
    },
    component: () => import('@/views/ElectronicComponents/electronic/librariesManage/partsLoader.vue'),
  },
  {
    path: '/docEdit',
    name: 'docEdit',
    meta:{
      login:"no"
    },
    component: () => import('@/views/homePage/PartView/RichText.vue'),
  },
  {
    path: '/partsReview',
    name: 'partsReview',
    meta:{
      login:"no"
    },
    component: () => import('@/views/DSNREVIEW/partsReview.vue'),
  },
  {
    path: '/ProductManage',
    component: () => import('@/views/productDesignManage/index'),
    name: '产品设计管理',
    menuName: '产品设计管理',
    meta: {login:"no"},
  },
  {
    path:'/need',
    name:'need',
    component: () => import('@/views/homePage/home/index'),
    redirect: '/need/applypart',
    children:[
      {
        path: 'applypart',
        name: 'applypart',
        component: () => import('@/views/homePage/needLogin/Apply/applypart'),
      },
      {
        path: 'applysearch',
        name: 'applysearch',
        component: () => import('@/views/homePage/needLogin/Apply/applysearch'),
      },
      {
        path: 'applyindex',
        name: 'applyindex',
        component: () => import('@/views/homePage/needLogin/Apply/applyindex'),
      },
      { path: 'bomupload', component:  () => import('@/views/homePage/needLogin/bommatches/bomupload'), name: 'BOM上传', icon: 'zonghe' },
      { path: 'mybomlist', component:  () => import('@/views/homePage/needLogin/bommatches/mybomlist'), name: '我的BOM', icon: 'zonghe' },
    ]
  },
  {
    path:'/NoNeed',
    name:'NoNeed',
    component: () => import('@/views/homePage/home/indexOther'),
    redirect: '/NoNeed/partindex',
    meta: {
      login:"no"
    },
    children:[
      {
        path: 'partindex',
        name: 'noneedpartindex',
        meta: {
          login:"no"
        },
        component: () => import('@/views/homePage/PartView/partindex'),
      },
      {
        path: 'manufacturer',
        name: 'noneedmanufacturer',
        meta: {
          login:"no"
        },
        component: () => import('@/views/homePage/PartView/manufacturer'),
      },
      {
        path: 'manufacturerMore',
        name: 'noneedmanufacturerMore',
        meta: {
          login:"no"
        },
        component: () => import('@/views/homePage/PartView/manufacturerMore'),
      },
      {
        path: 'manufacturerGoods',
        name: 'noneedmanufacturerGoods',
        meta: {
          login:"no"
        },
        component: () => import('@/views/homePage/PartView/manufacturerGoods'),
      },
      {
        path: 'datasheet',
        name: 'noneeddatasheet',
        meta: {
          login:"no"
        },
        component: () => import('@/views/homePage/PartView/datasheet'),
      },
      {
        path: 'partlists',
        name: 'noneedpartlists',
        meta: {
          login:"no"
        },
        component: () => import('@/views/homePage/PartView/partlists'),
      },
      {
        path: 'partdetail',
        name: 'noneedpartdetail',
        meta: {
          login:"no"
        },
        component: () => import('@/views/homePage/PartView/partdetail'),
      },
      {
        path: 'partclassify',
        name: 'noneedpartclassify',
        meta: {
          login:"no"
        },
        component: () => import('@/views/homePage/PartView/partclassify'),
      },
      {
        path: 'partpayment',
        name: 'noneedpartpayment',
        meta: {
          login:"no"
        },
        component: () => import('@/views/homePage/PartView/partpayment'),
      },
      {
        path: 'buildindex',
        name: 'noneedbuildindex',
        meta: {
          login:"no"
        },
        component: () => import('@/views/homePage/build/buildindex'),
      },
      {
        path: 'searchPart',
        name: 'noneedsearchPart',
        meta: {
          login:"no"
        },
        component: () => import('@/views/homePage/PartView/searchPart'),
      },
    ]
  },
]
export default home
