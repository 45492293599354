const state = {
    processKey: "",
    processInstanceId: "",
    nowEf55: "",
    processNowBtn: ""
}

const mutations = {
    setProcessKey(state, val) {
        state.processKey = val;
    },
    setProcessInstanceId(state, val) {
        state.processInstanceId = val;
    },
    setNowEf55(state, val) {
        state.nowEf55 = val;
    },
    setProcessNowBtn(state, val) {
        state.processNowBtn = val;
    },
}

export default {
    namespaced: true,
    state,
    mutations
}
