import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const FLO = [

];
export const FLOW = [
  {
    path: '/FLOW',
    component: Layout,
    redirect: '/FLOW/waitDo/',
    name: '流程管理',
    menuName:'流程管理',
    meta: {
      title: "流程管理",
      icon:"fa fa-sitemap",
    },
    children: [
          { path: 'waitDo', component:  () => import('@/views/ApprovalProcess/approval/waitDo'), name: '待办审批',menuName:'待办审批',meta:{title:"待办审批"}},
          { path: 'processDesign', component:  () => import('@/views/ApprovalProcess/approval/processDesign'), name: '流程设计',menuName:'流程设计',meta:{title:"流程设计"}},
          { path: 'runningProcess', component:  () => import('@/views/ApprovalProcess/approval/runningProcess'), name: '运行中流程',menuName:'运行中流程',meta:{title:"运行中流程"}},
          { path: 'yetDo', component:  () => import('@/views/ApprovalProcess/approval/yetDo'), name: '已完成审批',menuName:'已办审批',meta:{title:"已完成审批"}},
          { path: 'mySubmit', component:  () => import('@/views/ApprovalProcess/approval/mySubmit'), name: '我提交的',menuName:'我提交的',meta:{title:"我提交的"}},
    ]
  },
];
