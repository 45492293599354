import Layout from "@/views/layout/index";

export const ALTIUMFOOTPRINT = [
    {
        path: '/ALTIUMENCAPLIB',
        component: Layout,
        redirect: '/ALTIUMENCAPLIB/altiumEncapsulationList/',
        name: '元器件Altium封装库',
        menuName: 'Altium封装库',
        meta: {
            title: "Altium封装库",
            icon: "el-icon-c-scale-to-original",
        },
        children: [
            {
                path: 'altiumEncapsulationList',
                component: () => import('@/views/Altium/Footprints/altiumFootprints'),
                name: 'Altium封装库',
                menuName: 'Altium封装库',
                meta: {title: "Altium封装库"}
            },
        ]
    },
];

export const ALTIUMSYMBOL = [
    {
        path: '/ALTIUMSYMBOL',
        component: Layout,
        redirect: '/ALTIUMSYMBOL/altiumsymbolList/',
        name: '元器件Altium符号库',
        menuName: 'Altium符号库',
        meta: {
            title: "Altium符号库",
            icon: "el-icon-film",
        },
        children: [
            {
                path: 'altiumsymbolList',
                component: () => import('@/views/Altium/Symbols/altiumSymbols'),
                name: 'Altium符号库',
                menuName: 'Altium符号库',
                meta: {title: "Altium符号库"}
            },
        ]
    },
];
