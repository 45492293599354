import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";

export const BOARDBOMA = [
  {
    path: '/BOARDBOMA',
    component: Layout,
    redirect: '/BOARDBOMA/bomManagement/',
    name: '板卡BOM管理s',
    menuName: '板卡BOM管理',
    meta: {
        title: "板卡BOM管理",
        icon: "fa fa-outdent",
    },
    children: [
        {
            path: 'bomManagement',
            component: () => import('@/views/BoardcardBom/bomManagement'),
            name: '板卡BOMs',
            menuName: '板卡BOM',
            meta: {title: "板卡BOM"}
        },
        {
            path: 'boardCategoryDetail',
            component: () => import('@/views/BoardcardBom/boardCategoryDetail'),
            name: 'BOM详情展示',
            icon: 'zonghe'
        },
        {
            path: 'boardCategoryUpload',
            component: () => import('@/views/BoardcardBom/boardCategoryUpload'),
            name: 'BOM上传分析',
            icon: 'zonghe'
        },
        {
            path: 'bomHistory',
            component: () => import('@/views/BoardcardBom/bomHistory'),
            name: 'BOM历史记录',
            icon: 'zonghe'
        },
        {
            path: 'bomVersion',
            component: () => import('@/views/BoardcardBom/bomVersion'),
            name: 'BOM版本记录',
            icon: 'zonghe'
        },
        {
            path: 'bomOtherHistory',
            component: () => import('@/views/BoardcardBom/bomOtherHistory'),
            name: '附属文件历史记录',
            icon: 'zonghe'
        },
    ]
},
];
export const BOARDCARDBOM = [
    {
        path: '/BOARDCARDBOM',
        component: Layout,
        redirect: '/BOARDCARDBOM/bomManagement/',
        name: '板卡BOM管理',
        menuName: '板卡BOM管理',
        meta: {
            title: "板卡BOM管理",
            icon: "fa fa-outdent",
        },
        children: [
            {
                path: 'bomManagement',
                component: () => import('@/views/BoardcardBom/bomManagement'),
                name: '板卡BOM',
                menuName: '板卡BOM',
                meta: {title: "板卡BOM"}
            },
            {
                path: 'bomTemplateConfig',
                component: () => import('@/views/BoardcardBom/TemplateConfig/TemplateConfig'),
                name: 'BOM模板配置',
                menuName: 'BOM模板配置',
                meta: {title: "BOM模板配置"}
            },
        ]
    },
];
