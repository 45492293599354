<template>
  <!-- 流程申请样式-侧边栏组件 -->
  <div class="cb-drawer" :style="sstyle">
    <div v-if="open"
         :class="position === 'left' ? (open ? 'cb-drawer-open-mode': 'cb-drawer-close-mode') : ( open ? 'cb-drawer-open-mode-right': 'cb-drawer-close-mode-right')"
         @click="modeClick" :style="{opacity:zzc?'0.7':'0'}">
    </div>
    <div  :class="position === 'left' ? (open ? 'cb-drawer-open-content' : 'cb-drawer-close-content') : (open ? 'cb-drawer-open-content-right' :'cb-drawer-close-content-right') " :style="sstyle">
      <slot></slot>
    </div>
  </div>
</template>
<script>
  export default {
    componentName: "cb-drawer",
    name:'compdraw',
    props: {
      //控制遮罩是否可以点击隐藏抽屉组件
      closeByOutSideClick: {
        type: Boolean,
        default: false
      },
      //抽屉滑动方向
      position: {
        type: String,
        default: 'left'
      },
      width: {
        type: String,
        default: '50'
      },
      open: {
        type: Boolean,
        default:false,
      },
      //抽屉遮罩层是否显示
      zzc: {
        type: Boolean,
        default:true,
      },
      sstyle:{
        type:Object
      }
    },
    methods: {
      modeClick() {
        if (this.closeByOutSideClick) {
          this.$emit('update:open',false);
          this.$emit('change');
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .cb-drawer{
  &-close-content,&-open-content, &-close-content-right,&-open-content-right, &-close-mode ,&-open-mode,&-close-mode-right,&-open-mode-right{
    position: fixed;
    top: 0;
    height: 100%;
  }
  &-close-content,&-open-content,&-close-content-right,&-open-content-right{
    width: 50%;
    background: #fff;
    z-index: 99
  }
  &-open-mode,&-close-mode,&-close-mode-right,&-open-mode-right{
    background: #222222;
    opacity: 0.8;
    z-index: 9;
    width: 100%;
    right: 0;
    left: 0;
  }
  &-close-content{
    opacity:0;
    left: 0%;
    transform: translate3d(-100%, 0, 0);
    transition: all .5s;
  }
  &-open-content{
    opacity:1;
    left: 0%;
    transform: translate3d(0, 0, 0);
    transition:all  .5s;
  }
  &-close-content-right{
    right: -200%;
    transition: right .5s;
  }
  &-open-content-right{
    right: 0;
    transition: right .5s;
  }
  &-close-mode{
    opacity:0;
    transition: opacity .5s;
  }
  &-open-mode{
    opacity: 0.7;
    transition: opacity .5s;
  }
  &-close-mode-right{
    opacity:0;
    transition: opacity .5s;
  }
  &-open-mode-right{
    opacity: 0.7;
    transition: opacity .5s;
  }
}
  .cb-drawer-open-content,.cb-drawer-close-content{
    padding: 0px 0px 0px 0px;
  }
  .cb-drawer-open-content-right,.cb-drawer-close-content-right{
    padding: 0px 0px 0px 0px;
  }
</style>
