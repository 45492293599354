import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const MESSAG = [
    {
        path: '/MESSAG',
        component: Layout,
        redirect: '/MESSAG/innerMessage/',
        name: '消息中心S',
        children: [
          {
            path: 'innerMessage',
            component: MainLayout,
            name: '站内消息S',
            icon: 'zonghe',
            redirect: '/MESSAG/innerMessage/messageDetail',
            children: [
              { path: 'messageDetail', component:  () => import('@/views/MessageCenter/messages/innerMessage/messageDetail'), name: '消息详情S', icon: 'zonghe' },
            ]
          },
        ]
      },
];
export const MESSAGE = [
  {
    path: '/MESSAGE',
    component: Layout,
    redirect: '/MESSAGE/innerMessage/',
    name: '消息中心',
    menuName:'消息中心',
    meta: {
      title: "消息中心",
      icon:"fa fa-comments",
      
    },
    children: [
      { path: 'allMessage', component:  () => import('@/views/MessageCenter/message/innerMessage/allMessage'), name: '全部消息',menuName:'全部消息',meta:{title:"全部消息"}},
          { path: 'unreadMessage', component:  () => import('@/views/MessageCenter/message/innerMessage/unreadMessage'), name: '未读消息',menuName:'未读消息',meta:{title:"未读消息"}},
          { path: 'readMessage', component:  () => import('@/views/MessageCenter/message/innerMessage/readMessage'), name: '已读消息',menuName:'已读消息',meta:{title:"已读消息"}},
    ]
  },
];