import request from '@/utils/request';

/**
 * 获取消息列表
 */
export function readMessagePage(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/sysMessage/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取消息列表
 */
export function postMessagePage(params) {
    return request({
        url: '/api/v1/message',
        method: 'get',
        params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 批量改变消息状态
 */
export function updateMessageStatusList(data) {
    const params = JSON.stringify(data);
    return request({
        url: "/api/v1/sysMessage/read",
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 查看消息详情
 */
export function readMessageDetail(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/sysMessage/read/detail',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}
/**
 * 查看消息分类
 */
export function getMessageTypeList(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/sysMessageType/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}
/**
 * 删除消息
 */
export function deleteMessageList(data) {
    const params = JSON.stringify(data);
    return request({
        url: "/api/v1/sysMessage/delete",
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取消息详情
 */
export function getMessageDetail(msgId,status) {
    return request({
        url: `/api/v1/message-detail/${msgId}/${status}`,
        method: 'get',
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 标记消息已读
 */
export function postSetRead(ids) {
    return request({
        url: `/api/v1/message/set-read/${ids}`,
        method: 'post',
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 标记消息已读
 */
export function postSetAllRead(ids) {
    return request({
        url: `/api/v1/message/set-all-read`,
        method: 'post',
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 删除消息
 */
export function deleteAllMessage(ids) {
    return request({
        url: `/api/v1/message/${ids}`,
        method: 'delete',
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取自己的未读消息数量
 */
export function getMessageNum() {
    return request({
        url: `/api/v1/message/num-unread`,
        method: 'get',
        headers: {
            'content-type': 'application/json'
        }
    });
}
