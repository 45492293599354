<template>
<div>
  <!-- <app-main/> -->
  <router-view></router-view>
</div>
</template>
<script>
import { AppMain } from './components'
export default {
  name:'MainLayout',
  components:{AppMain}
   
}
</script>