import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from './lang';
import router from './router';
import store from "./store/index";
import '@/styles/index.scss';
import '@/utils/initJq.js';
import "@/utils/permission";
import {Message} from "element-ui";

// checkbox拖动调整顺序;
import VueDND from 'awe-dnd';
Vue.use(VueDND);

Vue.config.productionTip = false;
Vue.use(ElementUI, {
  size: 'small',
  i18n: (key, value) => i18n.t(key, value)
});
import * as filters from './filters' // global filters
// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
// 全局指令
import * as directives from "./directives";
Object.keys(directives).forEach(key => {
  Vue.directive(key, directives[key]);
})
import components from "./components";
Vue.use(components);
Vue.prototype.bus=new Vue();
Vue.prototype.DEEHOWBASEURL = location.protocol + "//" + location.host;
/**
 * 路由加载报错处理
 */
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    try {
      router.replace(targetPath);
    } catch {
      Message({
        message: '系统已升级，正在刷新本地存储，请稍候...',
        type: 'warning',
        duration: 1500,
        offset: 60
      });
      location.reload();
    }
  }
});
// 处理文件名截取后缀.前
Vue.prototype.$dealFileName = function (fileName){return fileName.split('.').slice(0, -1).join('.')};
// 获取文件后缀
Vue.prototype.$getFileSuffix = function (fileName) {return fileName.substring(fileName.lastIndexOf(".") + 1)};
// 判空方法
Vue.prototype.$isNotEmpty = function(obj){
  return (obj !== undefined && obj !== null && obj !== '' && obj !== 'null')
}

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  data: {
    eventHub: new Vue()
  }
}).$mount('#app')
